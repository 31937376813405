import moment from "moment";

// Explicitly set locale to 'en' when initializing and formatting
const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

// Add 7 days to initFromDate
const before7Days = moment(initDate)
  .locale("en")
  .subtract(7, "days")
  .format("YYYY-MM-DD");

// Add 30 days to initFromDate
const before30Days = moment(initDate)
  .locale("en")
  .subtract(30, "days")
  .format("YYYY-MM-DD");

// Helper function to convert object to query string
export const toQueryString = (obj) => {
  return Object.entries(obj)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
};

// handledData function
export const handledData = (dateRang, fromDate, toDate) => {
  if (
    dateRang?.shipmentDate?.fromDays === 7 ||
    dateRang?.requestDate?.fromDays === 7
  ) {
    return {
      [fromDate]: before7Days,
      [toDate]: initDate,
    };
  }

  if (
    dateRang?.shipmentDate?.fromDays === 30 ||
    dateRang?.requestDate?.fromDays === 30
  ) {
    return {
      [fromDate]: before30Days,
      [toDate]: initDate,
    };
  }
  if (dateRang?.shipmentDate?.fromDate || dateRang?.requestDate?.fromDate) {
    return {
      [fromDate]:
        dateRang?.shipmentDate?.fromDate ?? dateRang?.requestDate?.fromDate,
      [toDate]: dateRang?.shipmentDate?.toDate ?? dateRang?.requestDate?.toDate,
    };
  }
  return {};
};
