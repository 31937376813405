/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import {
  ADJUSTMENT_BY_ID_QUERY,
  DISAPPROVE_ADJUSTMENT_MUTATION,
} from "./Graphql";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleView, classesView } from "../../GlobalStyles/ViewStyle";
import { getEntityInfo } from "../../helpers/getEntityInfo";

const AdjustmentView = (props) => {
  const [pathURL] = useState(props.match.path);

  let pathname = "adjustment";

  const { t } = useTranslation();
  const id = props.match.params.id?.trim();
  const user = Globals.user;
  const [openDisapprove, setOpenDisapprove] = useState(false);
  const openDisapproveDialog = (type) => {
    setOpenDisapprove(true);
  };

  const { data, loading, refetch } = useQuery(
    gql`
      ${ADJUSTMENT_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(id) },
    }
  );
  const adjustmentData = data?.[pathname];

  const canEdit =
    user.hasPermission(`freight.adjustment.update`) &&
    !adjustmentData?.glApproved;

  const canDisapprove =
    user.hasPermission(`freight.${pathname}.disapprove`) &&
    adjustmentData?.approved;
  const icons = [
    {
      id: "edit",
      action: () => pushUrl(props, `/admin/${pathname}s/${id}/edit`),
      permission: canEdit,
    },
    {
      id: "disapprove",
      action: openDisapproveDialog,
      permission: Boolean(canDisapprove),
    },
  ];

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !adjustmentData ? (
    <NotFound />
  ) : (
    <>
      {openDisapprove && (
        <MutationWithDialog
          mutaion={DISAPPROVE_ADJUSTMENT_MUTATION.query}
          setOpenDelete={setOpenDisapprove}
          openDelete={openDisapprove}
          dialogTitle={t("disapprove")}
          dialogContent={t("disapprovedMessage")}
          mutaionProps={{
            variables: { id: parseInt(id) },
          }}
          onCompleted={() => refetch()}
          onCompleteMessage={t("successfullyDisapproved")}
        />
      )}
      <TitleAppBar path={pathURL}>
        <LongMenu icons={icons} />
      </TitleAppBar>

      <RootStyleView spacing={2} p={2} justifyContent="center">
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={adjustmentData?.code} />
          <KeyValuePair
            title={t("adjustmentDate")}
            value={adjustmentData?.adjustmentDate}
          />

          <KeyValuePair
            title={t("branch")}
            value={
              <SpanLink
                pathname={`/admin/branches/${adjustmentData?.branch?.id}`}
              >
                {adjustmentData?.branch?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("transactionType")}
            value={
              <SpanLink
                pathname={`/admin/transaction-types/${adjustmentData?.transactionType?.id}`}
              >
                {adjustmentData?.transactionType?.name}
              </SpanLink>
            }
          />

          <KeyValuePair title={t("type")} value={adjustmentData?.type?.name} />
        </Paper>
        <Paper container className={classesView.paper} component={Grid}>
          <KeyValuePair
            title={t("accountType")}
            value={adjustmentData?.accountType?.name}
          />

          <KeyValuePair
            title={t(adjustmentData?.accountType?.name)}
            value={
              <SpanLink
                pathname={`/admin/${getEntityInfo(adjustmentData).entityPath}/${
                  getEntityInfo(adjustmentData).id
                }`}
              >
                {adjustmentData[getEntityInfo(adjustmentData).entity]?.name}
              </SpanLink>
            }
          />

          <KeyValuePair
            title={t("documentType")}
            value={
              adjustmentData?.invoice
                ? t("invoice")
                : adjustmentData?.bill
                ? t("bill")
                : ""
            }
          />
          {
            <KeyValuePair
              title={t("documentCode")}
              value={
                <SpanLink
                  pathname={
                    adjustmentData?.invoice
                      ? `/admin/invoices/${adjustmentData?.invoice?.id}`
                      : adjustmentData?.bill
                      ? `/admin/bills/${adjustmentData?.bill?.id}`
                      : ""
                  }
                >
                  {adjustmentData?.invoice
                    ? adjustmentData?.invoice?.code
                    : adjustmentData?.bill
                    ? adjustmentData?.bill?.code
                    : ""}
                </SpanLink>
              }
            />
          }
          <KeyValuePair
            title={t("currency")}
            value={adjustmentData?.currency?.name}
          />
          <KeyValuePair
            title={t("exchangeRate")}
            value={adjustmentData?.exchangeRate}
          />
          <KeyValuePair title={t("value")} value={adjustmentData?.amount} />
          <KeyValuePair
            title={t("journalEntry")}
            value={
              <SpanLink
                pathname={`/admin/finance/journal-entries/${adjustmentData?.journalEntry?.id}`}
              >
                {adjustmentData?.journalEntry?.code}
              </SpanLink>
            }
          />
          <KeyValuePair title={t("notes")} value={adjustmentData?.remarks} />

          <KeyValuePair
            title={t("approved")}
            value={
              adjustmentData?.approved ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("glApproved")}
            value={
              adjustmentData?.glApproved ? (
                <Icon className={classesView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>
      </RootStyleView>
    </>
  );
};

export default AdjustmentView;
