import React, { useEffect } from "react";
import { Paper, Collapse, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { SAVE_BRANCH, BRANCH } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import {
  LIST_AREAS_DROPDOWN,
  LIST_CITIES_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";

import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import getMobileData, {
  getFormatNumber,
  validNumber,
} from "../../helpers/asYouType";
import { StyledLoading, classesLoad } from "../../GlobalStyles/LoadingStyle";
import { RootStyleForm, classesForm } from "../../GlobalStyles/FormStyle";

const BranchForm = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    country: null,
    state: null,
    city: null,
    area: null,
  });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    setError,
    getValues,
  } = useForm();
  const { errors } = formState;
  const branchId = parseInt(props.match.params.id);

  useEffect(() => {
    if (!branchId) {
      setValue("active", true);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectDefaultValue = (data) => {
    return data;
  };
  const { data: updateBranch, loading: updateLoading } = useQuery(
    gql`
      ${BRANCH.query}
    `,
    {
      skip: !branchId,
      notifyOnNetworkStatusChange: true,
      variables: { id: branchId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const branchData = data.branch;
        const branchParams = ["id", "code", "name", "address", "fax", "main"];

        // number quey conditions
        branchParams.forEach((i) => {
          branchData[i] !== null && setValue(i, branchData[i]);
        });
        const numbersObj = [
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(branchData.phone),
          },
        ];
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        setValue("active", branchData["active"]);

        setAutocompleteValues({
          country: branchData?.country,
          state: branchData?.state,
          city: branchData?.city,
          area: branchData?.area,
        });
      },
    }
  );

  const [saveBranch, { loading: saveBranchLoading }] = useMutation(
    gql`
      ${SAVE_BRANCH.query}
    `
  );

  const onSubmit = (data) => {
    const checkPhone = validNumber(data.phone, data.phoneCode, "notRequired");
    let names = [
      {
        name: "phone",
        validate: checkPhone.valid,
        message: checkPhone.message,
      },
    ];

    if (!checkPhone.valid) {
      names.map(
        (e) =>
          !e.validate &&
          setError(e.name, { type: "custom", message: t(e.message) })
      );
      return;
    }

    data.phone = data.phone && getFormatNumber(data.phone, data.phoneCode);
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
      if (key === "phoneCode") {
        delete data[key];
      }
    }
    saveBranch({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/branches/${data?.data?.saveBranch?.id}`);

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors?.[0]?.extensions.category === "validation") {
          setValidationError(graphQLErrors, setError);
        } else {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        aligns="center"
        className={classesForm.mainGrid}
        spacing={2}
      >
        <Paper container component={Grid} className={classesForm.spacing}>
          <Grid container justifyContent="end" xs={12}>
            <Box>
              <MuiSwitch
                edge="end"
                name="active"
                label={t("active")}
                control={control}
              />
              <MuiSwitch
                edge="end"
                name="main"
                label={t("main")}
                control={control}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>

          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>

          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"countryId"}
              label={t("country")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_COUNTRIES_DROPDOWN.query}
              onChangeValue={(e) => {
                setValue("stateId", "");
                setValue("cityId", "");
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.country}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("countryId")}
              skip={!watch("countryId")}
              name={"stateId"}
              label={t("state")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_STATES_DROPDOWN.query}
              variables={{
                countryId: watch("countryId"),
              }}
              onChangeValue={(e) => {
                setValue("cityId", "");
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.state}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("stateId")}
              skip={!watch("stateId")}
              name={"cityId"}
              label={t("city")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_CITIES_DROPDOWN.query}
              variables={{
                stateId: watch("stateId"),
              }}
              onChangeValue={(e) => {
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.city}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("cityId")}
              skip={!watch("cityId")}
              name={"areaId"}
              label={t("area")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_AREAS_DROPDOWN.query}
              variables={{
                cityId: watch("cityId"),
              }}
              defaultValue={autocompleteValues.area}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <MuiPhone
              setValue={setValue}
              codeValue={getValues().phoneCode}
              control={control}
              errors={errors}
              name={"phone"}
              selectName={"phoneCode"}
              label={t("mobile")}
            />
          </Grid>

          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"address"}
              label={t("address")}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"fax"}
              label={t("fax")}
            />
          </Grid>
        </Paper>
        <Grid
          container
          justifyContent="flex-end"
          className={classesForm.formButton}
        >
          <FormButton disabled={saveBranchLoading}>
            {saveBranchLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );

  return updateLoading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classesLoad.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !updateBranch && branchId ? (
    <NotFound />
  ) : (
    <RootStyleForm>
      <TitleAppBar path={props.match.path} />
      {body}
    </RootStyleForm>
  );
};

export default BranchForm;
