import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import SpanLinkNoLine from "../HOC/CustomComponents/SpanLinkNoLine";

export default function BasicCard(props) {
  const { item } = props;

  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        height: "100%",
        background: item?.color ? item?.color : "tomato",
      }}
    >
      <SpanLinkNoLine
        pathname={`admin/${item?.link}`}
        search={`${item?.search ?? ""}`}
      >
        <CardContent
          style={{ padding: 0, paddingBottom: "0px", width: "100%" }}
        >
          <Grid
            container
            m={0}
            justifyContent={"center"}
            alignItems={"center"}
            color={"grey"}
          >
            <Grid item xs={8}>
              <Typography
                variant="body2"
                component="div"
                sx={{ overflow: "hidden" }}
              >
                {item?.name}
              </Typography>
              <Typography variant="h6"> {item?.total}</Typography>
            </Grid>
            <Grid item xs={4} fontSize={"1.7rem"} textAlign={"end"}>
              <props.item.icon
                style={{
                  color: `${item?.iconColor ? item?.iconColor : "white"}`,
                  fontSize: "1.7rem",
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </SpanLinkNoLine>
    </Card>
  );
}
