import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Paper } from "@mui/material";
import CustomizedSteppers from "../HOC/FunctionComponents/Stepper";

import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { dateFormatLL } from "../../helpers/dateFunctions";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";

const PREFIX = "GlobalTrackView";

const classes = {
  logo: `${PREFIX}-logo`,
  appBar: `${PREFIX}-appBar`,
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  paper: `${PREFIX}-paper`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  powered: `${PREFIX}-powered`,
  poweredBy: `${PREFIX}-poweredBy`,
  li: `${PREFIX}-li`,
  lang: `${PREFIX}-lang`,
  status: `${PREFIX}-status`,
  createdAt: `${PREFIX}-createdAt`,
  center: `${PREFIX}-center`,
  cancellationReason: `${PREFIX}-cancellationReason`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  width: "100%",
  [`& .${classes.logo}`]: {
    width: "180px",
  },

  [`& .${classes.appBar}`]: {
    boxShadow: "none",
  },

  [`& .${classes.track}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    boxShadow: "none",
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    marginTop: theme.spacing(2),
    display: "grid",
  },

  [`& .${classes.powered}`]: {
    padding: theme.spacing(2),
    position: "absolute",
    bottom: "0",
  },

  [`& .${classes.poweredBy}`]: {
    padding: theme.spacing(2),
    position: "relative",
  },

  [`& .${classes.li}`]: {
    padding: " 7px 0",
  },

  [`& .${classes.lang}`]: {
    marginLeft: "auto",
  },

  [`& .${classes.status}`]: { marginLeft: theme.spacing(2) },
  [`& .${classes.createdAt}`]: { fontWeight: "bold" },

  [`& .${classes.center}`]: {
    minHeight: 500,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  [`& .${classes.cancellationReason}`]: {
    color: "#e53935",
    paddingLeft: "10px",
    fontSize: "12px",
  },
}));

const GlobalTrackView = (props) => {
  const { loading, data } = props;

  const { t, i18n } = useTranslation();
  const step1 = useRef([
    t("created"),
    t("packed"),
    t("loaded"),
    t("delivered"),
  ]);
  const date = new Date(data?.date);

  let transactions = new Map();
  // if (data && data) {
  //   data.transactions.forEach((element) => {
  //     const date = moment(element.createdAt).format("ll");
  //     if (!transactions.has(date)) {
  //       transactions.set(date, []);
  //     }
  //     transactions.get(date).push(element);
  //   });
  // }

  let tracking = null;

  if (loading) {
    tracking = (
      <Grid container item justifyContent="center" className={classes.center}>
        <FullScreenLoading />
      </Grid>
    );
  }

  if (data && !loading) {
    tracking = (
      <Grid container justifyContent="center">
        <Grid
          item
          className={clsx(classes.track, classes.paper)}
          component={Paper}
          container
        >
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
            sm={6}
            xs={12}
            item
            className={clsx(classes.textAlign)}
          >
            <Typography variant="body1" gutterBottom color="textSecondary">
              {t("requestCode")}
            </Typography>
            <Typography variant="h6" color="text.primary">
              {data?.code}
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
            sm={6}
            xs={12}
            item
            className={clsx(classes.textAlign)}
          >
            <Typography variant="body1" gutterBottom color="textSecondary">
              {t("shipmentStatus")}
            </Typography>
            <Typography variant="h6" color="text.primary">
              {data?.status.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item justifyContent="center" className={classes.track}>
          <CustomizedSteppers
            step1={step1}
            key={i18n.language}
            statusCode={data?.status?.code}
            type={"request"}
          />
        </Grid>
      </Grid>
    );
  } else if (!loading) {
    tracking = <EmptyTableMessage message={t("noResult")} />;
  }
  return (
    <Root>
      {tracking}
      {/* <Grid
        container
        item
        justifyContent="center"
        className={clsx({
          [classes.poweredBy]: data && !loading,
          [classes.powered]: !data || loading,
        })}
      ></Grid> */}
    </Root>
  );
};

export default GlobalTrackView;
