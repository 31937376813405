import React, { memo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Grid from "@mui/material/Unstable_Grid2";
import PieChart, {
  Animation,
  Font,
  Legend,
  Series,
} from "devextreme-react/pie-chart";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import * as gqlb from "gql-query-builder";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import TooltipTemplate from "./CenterTemplate";
import StatisticsFilterHeader from "./StatisticsFilterHeader";
import BasicCard from "./AppSummary";

import { VscInbox } from "react-icons/vsc";
import { LuPackageCheck } from "react-icons/lu";
import { LANGUAGES_DETAILS } from "../../helpers/LanguagesVariables";
import { GrTransaction } from "react-icons/gr";
import { FaCheck } from "react-icons/fa6";
import { CgNotes } from "react-icons/cg";
import { Box, Paper } from "@mui/material";
import useWidth from "../../Hooks/useWidth";
import { handledData, toQueryString } from "./dateSearch";
const PREFIX = "PieChart";
const classes = { textColor: `${PREFIX}-textColor` };

const StyledCard = styled(Paper)(({ theme }) => ({
  width: "100%",
  minHeight: "350px !important",
  [`& .${classes.textColor}`]: { color: theme.palette.text.primary },
  borderRadius: "10px",
}));

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.textColor}`]: {
    [`& text`]: { fill: `${theme.palette.text.primary} !important` },
  },
}));

const ICONS = {
  pckd: LuPackageCheck,
  transporting: GrTransaction,
  delivered: FaCheck,
  rqst: CgNotes,
  rcvd: VscInbox,
  arrived: FaCheck,
  loading: VscInbox,
  unloaded: VscInbox,
};

const COLORS = {
  rqst: "#e2dbdb",
  transporting: "#f8dddd",
  delivered: "#d3fcdc",
  pckd: "#e5f6fe",
  rcvd: "#676363",
  arrived: "#676363",
  loading: "#676363",
  unloaded: "#676363",
};
const ICON_COLORS = {
  pckd: "#58b9f0",
  transporting: "#bb4a4a",
  delivered: "#37c563",
  rqst: "#676363",
  rcvd: "#676363",
  arrived: "#676363",
  loading: "#676363",
  unloaded: "#676363",
};
const tabName = {
  pckd: "packed",
  transporting: "transport",
  delivered: "delivered",
  rqst: "request",
  rcvd: "received",
  arrived: "arrived",
  loading: "loading",
  unloaded: "unloaded",
};

// GraphQL Query
const STATISTICS = gqlb.query(
  [
    "totalRequest",
    "rcvdRequest",
    "loadingRequest",
    "transportingRequest",
    "arrivedRequest",
    "unloadedRequest",
    "deliveredRequest",
    "pckdRequest",
    "rqstRequest",
  ].map((operation) => ({
    operation: `${operation}:listRequests`,
    fields: [{ operation: "paginatorInfo", fields: ["total"], variables: {} }],
    variables: {
      first: { type: "Int" },
      [`${operation}Input`]: { name: "input", type: "ListRequestsFilterInput" },
    },
  }))
);

// Main Component
const PieChartsRequest = ({
  dateRange,
  inputs,
  onDateRangeAccept,
  handleDateRange,
  onFocus,
  classNamePieChart,
}) => {
  const { t, i18n } = useTranslation();
  const lang = LANGUAGES_DETAILS[i18n.language];

  const { data, loading } = useQuery(
    gql`
      ${STATISTICS.query}
    `,
    {
      fetchPolicy: "no-cache",
      variables: {
        first: 1,
        totalRequestInput: { ...dateRange, ...inputs },
        ...[
          "rcvd",
          "rqst",
          "loading",
          "transporting",
          "arrived",
          "delivered",
          "unloaded",
          "pckd",
        ].reduce(
          (acc, status) => ({
            ...acc,
            [`${status}RequestInput`]: {
              status: [status.toUpperCase()],
              ...dateRange,
            },
          }),
          {}
        ),
      },
    }
  );

  const [isAnimating, setIsAnimating] = useState(true);

  const handlePointClick = (e) => {
    const { color, key } = e.target.data;
    setIsAnimating(false);
    onFocus(color, key);
  };

  // Generate status data
  const handledDataResult = handledData(
    dateRange,
    "requestFromDate",
    "requestToDate"
  ); // Call handledData here
  const queryString = toQueryString(handledDataResult);
  const createStatusData = (status) => ({
    name: t(tabName[status]),
    total: data?.[`${status}Request`]?.paginatorInfo?.total || 0,
    percentage:
      ((data?.[`${status}Request`]?.paginatorInfo?.total || 0) /
        (data?.totalRequest?.paginatorInfo?.total || 1)) *
      100,
    color: COLORS[status],
    icon: ICONS[status],
    iconColor: ICON_COLORS[status],
    link: "requests",
    search: `status=${status.toUpperCase()}&${queryString}`,
  });

  const statuses = ["rqst", "pckd", "transporting", "delivered"];
  const additionalStatuses = ["rcvd", "arrived", "loading", "unloaded"];
  const dataSource = statuses.map(createStatusData);
  const dataCard = [...dataSource, ...additionalStatuses.map(createStatusData)];
  const screenWidth = useWidth();
  const width = {
    ...(screenWidth === "xs" && { width: 260 }),
  };
  const renderChart = () => (
    <Root>
      <PieChart
        id="pie"
        type="donut"
        palette="Material"
        dataSource={dataCard}
        resolveLabelOverlapping="shift"
        innerRadius={0.65}
        centerRender={TooltipTemplate(t("total"), lang)}
        style={{ height: "17rem" }}
        size={width}
        onPointClick={handlePointClick}
        className={clsx(classNamePieChart, classes.textColor)}
      >
        <Legend
          orientation="horizontal"
          itemTextPosition="right"
          horizontalAlignment="center"
          verticalAlignment="bottom"
          rowCount={2}
        >
          <Font family={lang.fontFamily} />
        </Legend>
        <Series argumentField="name" valueField="total" />
        <Animation enabled={isAnimating} />
      </PieChart>
    </Root>
  );

  return (
    <div style={{ paddingInline: "8px" }}>
      <StyledCard elevation={5}>
        <Grid container spacing={2} m={0}>
          <Grid xs={12}>
            <StatisticsFilterHeader
              section="pieChart"
              onDateRangeAccept={onDateRangeAccept}
              handleDateRange={handleDateRange}
              title={t("requestStatistics")}
              icon={ICONS.pckd}
            />
          </Grid>

          {loading ? (
            <Grid
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ height: 250 }}
            >
              <CustomSpinner name="PulseLoader" size={12} margin={2} />
            </Grid>
          ) : (
            <Box width={"100%"}>
              <Grid container justifyContent="space-around" m={0} spacing={2}>
                <Grid xs={12} sm={6} alignItems="center">
                  {renderChart()}
                </Grid>
                <Grid xs={12} md={6}>
                  <Box>
                    <Grid container spacing={3} m={0}>
                      {dataSource.map((item, index) => (
                        <Grid xs={12} sm={6} key={index}>
                          <BasicCard item={item} />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </StyledCard>
    </div>
  );
};

export default memo(PieChartsRequest);
