import React from "react";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import Grid from "@mui/material/Unstable_Grid2";
import { GoContainer } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { RiShip2Line } from "react-icons/ri";
import { CgNotes } from "react-icons/cg";
import { LuPlaneTakeoff } from "react-icons/lu";
import { LiaShippingFastSolid } from "react-icons/lia";
import BasicCard from "./AppSummary";
import { Globals } from "../HOC/Classes/Globals";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { Box, Paper } from "@mui/material";

// Queries
const queries = {
  listShipments: {
    operation: "listShipments",
    fields: [{ operation: "paginatorInfo", fields: ["total"], variables: {} }],
    variables: {
      first: { type: "Int" },
      shipmentsInput: { name: "input", type: "ListShipmentsFilterInput" },
    },
  },
  listRequests: {
    operation: "listRequests",
    fields: [{ operation: "paginatorInfo", fields: ["total"], variables: {} }],
    variables: {
      first: { type: "Int" },
      requestsInput: { name: "input", type: "ListRequestsFilterInput" },
    },
  },
  listRequestOcean: {
    operation: "listRequestOcean:listRequests",
    fields: [{ operation: "paginatorInfo", fields: ["total"], variables: {} }],
    variables: {
      first: { type: "Int" },
      listRequestOceanInput: {
        name: "input",
        type: "ListRequestsFilterInput!",
      },
    },
  },
  listRequestAir: {
    operation: "listRequestAir:listRequests",
    fields: [{ operation: "paginatorInfo", fields: ["total"], variables: {} }],
    variables: {
      first: { type: "Int" },
      listRequestAirInput: {
        name: "input",
        type: "ListRequestsFilterInput!",
      },
    },
  },
  listRequestLand: {
    operation: "listRequestLand:listRequests",
    fields: [{ operation: "paginatorInfo", fields: ["total"], variables: {} }],
    variables: {
      first: { type: "Int" },
      listRequestLandInput: {
        name: "input",
        type: "ListRequestsFilterInput!",
      },
    },
  },
};

const elementsConfig = (t, data) => [
  ...(data?.listShipments
    ? [
        {
          id: 1,
          name: t("totalShipments"),
          total: data?.listShipments?.paginatorInfo?.total,
          color: "#d3fcdc",
          icon: GoContainer,
          iconColor: "#37c563",
          link: "shipments",
        },
      ]
    : []),
  ...(data?.listRequests
    ? [
        {
          id: 5,
          name: t("totalRequests"),
          total: data?.listRequests?.paginatorInfo?.total,
          color: "#f8dddd",
          icon: CgNotes,
          iconColor: "#bb4a4a",
          link: "requests",
        },
      ]
    : []),
  {
    id: 2,
    name: t("oceanRequest"),
    total: data?.listRequestOcean?.paginatorInfo?.total,
    color: "#e5f6fe",
    icon: RiShip2Line,
    iconColor: "#58b9f0",
    link: "requests",
    search: "shippingMode=OCEAN",
  },
  {
    id: 3,
    name: t("airRequest"),
    total: data?.listRequestAir?.paginatorInfo?.total,
    color: "#e2e8ff",
    icon: LuPlaneTakeoff,
    iconColor: "#435fc2",
    link: "requests",
    search: "shippingMode=AIR",
  },
  {
    id: 4,
    name: t("landRequest"),
    total: data?.listRequestLand?.paginatorInfo?.total,
    color: "#fdf0e7",
    icon: LiaShippingFastSolid,
    iconColor: "#f39d64",
    link: "requests",
    search: "shippingMode=LAND",
  },
];

const PendingApproval = (props) => {
  const { t } = useTranslation();
  const user = Globals?.user;
  const listRequestPermission = user.hasPermission("freight.request.list");
  const listShipmentPermission = user.hasPermission("freight.shipment.list");
  const activeQueries = listRequestPermission
    ? [
        queries.listRequests,
        queries.listRequestOcean,
        queries.listRequestAir,
        queries.listRequestLand,
      ]
    : [];
  listShipmentPermission && activeQueries.push(queries.listShipments);
  const LIST_ALL = activeQueries.length ? gqlb.query(activeQueries) : null;

  const { data, loading } = useQuery(
    gql`
      ${LIST_ALL?.query || ""}
    `,
    {
      fetchPolicy: "no-cache",
      skip: !listRequestPermission,
      variables: {
        first: 1,
        ...(listRequestPermission && {
          listRequestOceanInput: { shippingMode: ["OCEAN"] },
          listRequestAirInput: { shippingMode: ["AIR"] },
          listRequestLandInput: { shippingMode: ["LAND"] },
        }),
      },
    }
  );

  const listData = elementsConfig(t, data);

  return (
    <StatisticsCard
      {...props}
      listData={listData}
      loading={loading}
      listShipmentPermission={listShipmentPermission}
      listRequestPermission={listRequestPermission}
    />
  );
};

const StatisticsCard = ({
  listData,
  loading,
  listRequestPermission,
  listShipmentPermission,
}) =>
  loading ? (
    <Box width={"100%"}>
      <Grid container m={0}>
        {[...Array(5)].map((_, index) => (
          <Grid xs={12} sm={6} md={12 / 5} key={index} style={{ height: 100 }}>
            <Paper
              sx={{
                borderRadius: "10px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomSpinner name="PulseLoader" size={12} margin={2} />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : (
    <Box width={"100%"}>
      <Grid container spacing={2}>
        {listData.map((item, index) => (
          <Grid
            xs={12}
            sm={6}
            md={
              listRequestPermission && listShipmentPermission
                ? 12 / 5
                : listRequestPermission
                ? 3
                : listShipmentPermission && 6
            }
            key={index}
          >
            <BasicCard item={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

export default PendingApproval;
