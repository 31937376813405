import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
import CellLink from "../HOC/CustomComponents/CellLink";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";

const PREFIX = "TreasurersTable";

const classes = {
  p: `${PREFIX}-p`,
  tap: `${PREFIX}-tap`,
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  destinations: `${PREFIX}-destinations`,
  iconColor: `${PREFIX}-iconColor`,
  deleteIcon: `${PREFIX}-deleteIcon`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingTop: "16px",
  [`& .${classes.p}`]: {
    fontSize: "14px",
    margin: "2px 1px",
    width: 100,
  },

  [`& .${classes.tap}`]: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // flexDirection: "column",
    textTransform: "capitalize",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.destinations}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.deleteIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const TreasurersTable = (props) => {
  const { treasurers = [], addTreasurers, setTreasurersList } = props;
  const { t } = useTranslation();
  const [treasurersIndex, setTreasurersIndex] = useState(0);
  const [treasurersPage, setTreasurersPage] = useState(0);
  const [treasurersRowsPerPage, setTreasurersRowsPerPage] = useState(20);

  const handleTreasurersPage = (event, newPage) => {
    setTreasurersPage(newPage);
  };
  const handleTreasurersRowsPerPage = (event) => {
    setTreasurersRowsPerPage(parseInt(event.target.value));
    setTreasurersPage(0);
  };

  return (
    <StyledGrid container alignItems="flex-start">
      <Grid container item sm={12} component={Paper}>
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("treasurers")}
          </Typography>

          {addTreasurers ? (
            <IconButton
              color="primary"
              onClick={() => {
                addTreasurers();
                setTreasurersIndex(0);
              }}
              aria-label="Add treasurers"
              size="large"
            >
              <Add />
            </IconButton>
          ) : null}
        </Toolbar>
        <Grid container item className={classes.table}>
          {treasurers.length <= 0 ? (
            <EmptyTableMessage loading={false} message={t("noTreasurers")} />
          ) : (
            <TableFixedHeaderWraper className={classes.tableContainer}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("treasurer")}</FixedTableCell>
                    {/* <FixedTableCell>{t("active")}</FixedTableCell> */}
                    {addTreasurers ? <FixedTableCell></FixedTableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {treasurers.length > 0 &&
                    (treasurersRowsPerPage > 0
                      ? treasurers.slice(
                          treasurersPage * treasurersRowsPerPage,
                          treasurersPage * treasurersRowsPerPage +
                            treasurersRowsPerPage
                        )
                      : treasurers
                    ).map((row, index) => (
                      <TableRow
                        className={clsx({
                          [classes.focus]:
                            treasurersPage * treasurersRowsPerPage + index ===
                            treasurersIndex,
                        })}
                        hover
                        key={index}
                        onClick={() => {
                          setTreasurersPage(0);
                          setTreasurersIndex(
                            treasurersPage * treasurersRowsPerPage + index
                          );
                        }}
                      >
                        <CellLink
                          pathname={`/admin/users/${row?.user?.id}`}
                          key={index}
                        >
                          {row.user.name ?? row.user.username}
                        </CellLink>

                        {addTreasurers ? (
                          <FixedTableCell>
                            <IconButton
                              size="small"
                              onClick={() =>
                                addTreasurers(
                                  treasurersPage * treasurersRowsPerPage + index
                                )
                              }
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              size="small"
                              className={classes.deleteIcon}
                              onClick={() =>
                                setTreasurersList((prev) => {
                                  const filterd = prev.filter(
                                    (i, deleteIndex) => deleteIndex !== index
                                  );
                                  return filterd;
                                })
                              }
                            >
                              <Delete />
                            </IconButton>
                          </FixedTableCell>
                        ) : null}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          )}
          <MUITablePagination
            count={treasurers?.length ?? 0}
            rowsPerPage={treasurersRowsPerPage}
            page={treasurersPage}
            onPageChange={handleTreasurersPage}
            onRowsPerPageChange={handleTreasurersRowsPerPage}
          />
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default TreasurersTable;
